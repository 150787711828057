<template>
  <div />
</template>

<script>

export default {
  components: {
  },
  setup() {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
